/**
 * @author: Jonas Tandinco
 * @create: 9 March 2018
 *
 * Manages external content which can list the contents of a directory from a source such as Google Drive
 * Tightly bound to DOM
 */

const LOCAL_GDRIVE_NAME = "googleDriveLink";

class ExternalContentController {

    /**
     * @param externalContentApi - object
     * @param options - object
     */
    constructor(externalContentApi, options) {
        this.externalContentApi = externalContentApi;
        this.source = null;
        this.directoryContainer = null;
        this.previewContainer = null;
        this.viewMode = 'directory';

        this.loader = null;

        this.noLinkErrorShown = false;

        // for font resize
        this.textScaleFactors = [1, 2, 3, 4, 5, 6];
        this.curScaleFactorPointer = 1;
        this.defaultFontSize = null;
        this.gDriveId = null;

        let self = this;

        this.fontSize = '2';
        this.fontFamily = 'Arial';
        this.fontSizeAdjusterToggle = true;
        this.thumbNailsPerRow = 7;

        this.directoryName = '';
        this.fileName = '';

        // validate options
        let gDriveLink = LocalStorageWrapper.get(LOCAL_GDRIVE_NAME);

        // dom directory must be set
        if (!options.domDirectoryId || !document.getElementById(options.domDirectoryId)) {
            this.redirectToHelpPage();
            return;
        }

        // dom preview must be set
        if (!options.domPreviewId || !document.getElementById(options.domPreviewId)) {
            this.redirectToHelpPage();
            return;
        }

        this.directoryContainer = document.getElementById(options.domDirectoryId);
        this.previewContainer = document.getElementById(options.domPreviewId);
        this.headerLabel = document.getElementById('my_stuff_header_label');

        if (options.fontFamily) {
            this.setFontFamily(options.fontFamily);
        }

        if (options.fontSize) {
            this.setFontSize(options.fontSize);
        }

        if (options.fontSizeAdjusterToggle) {
            this.setFontSizeAdjusterToggle(options.fontSizeAdjusterToggle);
        }

        if (options.thumbnailsPerRow) {
            this.setThumbnailsPerRow(options.thumbnailsPerRow);
        }

        this.externalContentApi.init();

        self.directoryContainer.addEventListener('click', function (evt) {
            if (self.noLinkErrorShown) {
                return;
            }

            evt.preventDefault();
            evt.stopPropagation();

            console.log('MY STUFF: Clicked inside the directory.');

            let el = evt.target;
            if (['a', 'img'].indexOf(el.tagName.toLowerCase()) >= 0) {
                el = el.parentNode;

                let extension = ExternalContentController.getFileExtension(el.getAttribute('data-filename'));
                if (extension.trim().toLocaleLowerCase() === 'pdf') {
                    self.hideFontSizeAdjuster();
                }
                else {
                    self.showFontSizeAdjuster();
                }

                self.fileName = decodeURIComponent(ExternalContentController.trimFileExtension(el.getAttribute('data-filename')));
                self.switchViewMode('preview');
                // self.previewContainer.innerHTML = '<img src="/wp-content/uploads/2018/03/ajax-loader.gif" />';
                self.showLoadingImage();

                const fileId = el.getAttribute('data-id');
                const mimeType = decodeURIComponent(el.getAttribute('data-type'));

                let options = {
                    fontSize: self.fontSize + 'vw',
                    fontFamily: self.fontFamily,
                };

                // Returns a promise that could be used for signaling completion or error
                self.externalContentApi.showFile(self.previewContainer, fileId, mimeType, options).then(function(response) {
                    self.hideLoadingImage();

                }).catch(function(err) {
                    self.hideLoadingImage();
                    console.log(err);
                    self.previewContainer.textContent = 'Failed loading file';
                });
            }
        });

        // @todo: breaks encapsulation, refactor
        document.getElementById('my_stuff_button').addEventListener('click', function(event) {
            const isLocked = [null, 'true'].indexOf(LocalStorageWrapper.get('isMyStuffSourceLocked')) !== -1;

            if (self.viewMode === 'preview') {
                if (isLocked) {
                    self.loadDirectoryContents();
                } else {
                    self.switchViewMode('directory');
                }

            }
            else if (self.viewMode === 'directory'){
                if (!isLocked) {
                    let existing = '';
                    if (LocalStorageWrapper.get(LOCAL_GDRIVE_NAME)) {
                        existing = LocalStorageWrapper.get(LOCAL_GDRIVE_NAME);
                    }

                    let source = prompt('Google Drive Link', existing);
                    if (source !== null) {
                        LocalStorageWrapper.set(LOCAL_GDRIVE_NAME, source);
                        self.externalContentApi.extractIdFromGoogleDriveUrl(source).then(function(response) {
                            self.noLinkErrorShown = false;
                            self.gDriveId = response.driveId;
                            self.loadDirectoryContents();
                        }).catch(function(err) {
                            console.log(err);
                        });
                    }
                }
                else {
                    if (LocalStorageWrapper.get(LOCAL_GDRIVE_NAME)) {
                        self.externalContentApi.extractIdFromGoogleDriveUrl(LocalStorageWrapper.get(LOCAL_GDRIVE_NAME)).then(function(response) {
                            self.noLinkErrorShown = false;
                            self.gDriveId = response.driveId;
                            self.loadDirectoryContents();
                        }).catch(function(err) {
                            console.log(err);
                        });
                    } else {
                        self.showErrorMessage(options.introContent);
                    }
                }
            }
        });

        if (!gDriveLink) {
            if (options.introContent) {
                self.showErrorMessage(options.introContent);
            }

            return;
        }

        this.externalContentApi.extractIdFromGoogleDriveUrl(gDriveLink).then(function(response) {
            console.log('Successfully extracted google drive link');
            self.gDriveId = response.driveId;
            self.loadDirectoryContents();
        }).catch(function(err) {
            console.log(err);
        });

        const fontSizeAdjusterPlus = document.getElementById('my_stuff_plus_size');
        const fontSizeAdjusterMinus = document.getElementById('my_stuff_minus_size');

        fontSizeAdjusterPlus.addEventListener('click', function(event) {
            if (self.viewMode !== 'preview') {
                return;
            }

            // limit reached
            if (self.curScaleFactorPointer === self.textScaleFactors.length - 1) {
                return;
            }

            self.curScaleFactorPointer++;
            self.externalContentApi.setScale({fontSize: self.textScaleFactors[self.curScaleFactorPointer] + 'vw'});
        });

        fontSizeAdjusterMinus.addEventListener('click', function(event) {
            if (self.viewMode !== 'preview') {
                return;
            }

            // limit reached
            if (self.curScaleFactorPointer === 0) {
                return;
            }

            self.curScaleFactorPointer--;
            self.externalContentApi.setScale({fontSize: self.textScaleFactors[self.curScaleFactorPointer] + 'vw'});
        });
    }

    showErrorMessage(introContent) {
        this.noLinkErrorShown = true;
        this.directoryContainer.innerHTML = decodeURIComponent(introContent);

        if (typeof playAudioManager !== 'undefined') {
            playAudioManager.registerRegion(this.directoryContainer);
        }
    }

    redirectToHelpPage() {
        console.log('Redirecting to help page.');
        location.href = "/mystuffintro";
    }

    /**
     * Configure the number of thumbnails per row
     * @param num
     */
    setThumbnailsPerRow(num) {
        num = parseInt(num);

        if (num === 0) {
            this.thumbNailsPerRow = 7;
        } else {
            this.thumbNailsPerRow = num;
        }

        console.log('Set thumbnails per row = ' + num);
        return this;
    }

    setFontFamily(fontFamily) {
        this.fontFamily = decodeURIComponent(fontFamily);
        return this;
    }

    setFontSize(fontSize) {
        this.fontSize = fontSize;
        this.curScaleFactorPointer = this.textScaleFactors.indexOf(fontSize);

        this.directoryContainer.style.fontSize = fontSize + 'vw';
        this.directoryContainer.style.marginTop = fontSize + 'vw';

        return this;
    }

    setFontSizeAdjusterToggle(val) {
        if (val) {
            this.showFontSizeAdjuster();
        } else {
            this.hideFontSizeAdjuster();
        }

        return this;
    }

    showFontSizeAdjuster() {
        const plusButton = document.getElementById('my_stuff_plus_size');
        const minusButton = document.getElementById('my_stuff_minus_size');

        plusButton.style.visibility = 'visible';
        minusButton.style.visibility = 'visible';
    }

    hideFontSizeAdjuster() {
        const plusButton = document.getElementById('my_stuff_plus_size');
        const minusButton = document.getElementById('my_stuff_minus_size');

        plusButton.style.visibility = 'hidden';
        minusButton.style.visibility = 'hidden';
    }

    showFontSizeAdjuster() {
        const plusButton = document.getElementById('my_stuff_plus_size');
        const minusButton = document.getElementById('my_stuff_minus_size');

        plusButton.style.visibility = 'visible';
        minusButton.style.visibility = 'visible';
    }

    hideFontSizeAdjuster() {
        const plusButton = document.getElementById('my_stuff_plus_size');
        const minusButton = document.getElementById('my_stuff_minus_size');

        plusButton.style.visibility = 'hidden';
        minusButton.style.visibility = 'hidden';
    }

    /**
     * changes the viewing mode from directory to file content and vice versa
     * @param mode
     */
    switchViewMode(mode) {
        this.viewMode = mode;
        let label = '';

        let stringTrim = function(string, length) {
            if (string.length > length) {
                string = string.substring(0, length);
                string += '...';
            }

            return string;
        };

        if (mode.toLowerCase() === 'directory') {
            this.previewContainer.style.display = 'none';
            this.directoryContainer.style.display = 'block';
            this.headerLabel.textContent = stringTrim(this.directoryName, 20);
        }
        else if (mode.toLowerCase() === 'preview') {
            this.previewContainer.style.display = 'block';
            this.directoryContainer.style.display = 'none';
            this.headerLabel.textContent = stringTrim(this.fileName, 20);
        }
    }

    /**
     * helper function to remove all of elements children
     * @param el
     */
    static emptyElement(el) {
        while (el.firstChild) {
            el.removeChild(el.firstChild);
        }
    }

    /**
     * Adopted from https://stackoverflow.com/a/4250408/1251343
     * @param filename
     * @returns {string|*|XML|void}
     */
    static trimFileExtension(filename) {
        return filename.replace(/\.[^/.]+$/, "");
    }

    /**
     * extracts the extension of the filename
     * Ref: https://stackoverflow.com/a/1203361/1251343
     * @param filename
     */
    static getFileExtension(filename) {
        return filename.split('.').pop();
    }

    showLoadingImage() {
        if (this.loader === null) {
            this.loader = document.createElement('img');

            this.loader.style.position = 'fixed';
            this.loader.style.left = '0';
            this.loader.style.top = '0';
            this.loader.style.width = '100%';
            this.loader.style.height = '100%';
            this.loader.style.zIndex ='9999';
            this.loader.style.background = 'url("/wp-content/uploads/2018/03/ajax-loader.gif") 50% 50% no-repeat rgb(249,249,249)';
        }

        document.body.appendChild(this.loader);
    }

    hideLoadingImage() {
        document.body.removeChild(this.loader);
    }

    loadDirectoryContents() {
        let self = this;

        ExternalContentController.emptyElement(this.previewContainer);

        this.switchViewMode('directory');
        // this.directoryContainer.innerHTML = '<img src="/wp-content/uploads/2018/03/ajax-loader.gif" />';
        this.showLoadingImage();

        this.externalContentApi.getDirectory(this.gDriveId).then(function (response) {
            self.hideLoadingImage();
            if (response.files.length === 0) {
                self.directoryContainer.innerHTML = 'No content found!';
                return;
            }

            ExternalContentController.emptyElement(self.directoryContainer);
            self.directoryName = response.name;
            self.switchViewMode('directory');

            let table = document.createElement('table');
            table.style.width = '86vw';
            table.style.borderCollapse = 'separate';

            // compute border spacing
            let cellSpace = 0;
            if (self.thumbNailsPerRow + 1 !== 0) {
                cellSpace = parseInt((86 - (7 * self.fontSize * self.thumbNailsPerRow)) / (self.thumbNailsPerRow + 1));

                if (cellSpace <= 0) {
                    cellSpace = 0;
                } else {
                    cellSpace += 'vw';
                }
            }

            table.style.borderSpacing = cellSpace + ' 1em';

            let i = 0;
            let currentRow;
            response.files.forEach(function (file) {
                if (i % self.thumbNailsPerRow === 0) {
                    // start a new row
                    currentRow = document.createElement('tr');
                    // currentRow.style.marginBottom = self.fontSize + 'vw';
                    table.appendChild(currentRow);
                }

                const item = document.createElement('td');
                item.style.width = 7 * self.fontSize + 'vw';
                item.style.verticalAlign = 'top';
                item.style.textAlign = 'center';
                item.style.paddingBottom = self.fontSize + 'vw';

                item.setAttribute('data-id', encodeURIComponent(file.id));
                item.setAttribute('data-type', encodeURIComponent(file.mimeType));
                item.setAttribute('data-filename', encodeURIComponent(file.name));

                const img = document.createElement('img');
                img.classList.add('mystuff-thumb-file');
                img.src = file.thumbnail;
                img.style.width = '90%';
                // img.style.width = 7 * self.fontSize - 1 + 'vw';;

                const linkText = document.createElement('a');
                linkText.setAttribute('href', '#');
                linkText.innerHTML = ExternalContentController.trimFileExtension(file.name);

                if (LocalStorageWrapper.get('isMyStuffThumbnailsShown') !== 'true') {
                    img.style.display = 'none';
                }

                item.appendChild(img);
                item.appendChild(document.createElement('br'));
                item.appendChild(linkText);
                currentRow.appendChild(item);

                i++; // prefer explicit incrementation
            });

            self.directoryContainer.appendChild(table);

        }).catch(function (err) {
            self.hideLoadingImage();
            console.log(err);
            self.directoryContainer.innerHTML = 'There was an error loading the directory contents';
        });
    }

}